import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../../components/SEO'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

import PageContent from '../../old/pages/AMFMRadioStreaming'
import '../../assets/styles.css'

export default ({ data }) => {
  return (
    <React.Fragment>
      <SEO
        title={'AM/FM Radio Streaming'}
        description={
          'Live365 for AM FM Radio Streaming offers an easy and powerful way to host and distribute your terrestrial station over the internet. Live365 enables AM FM stations to distribute and monetize their audience quickly and easily.'
        }
      />
      <Header
        title={'AM/FM Radio Streaming'}
        lightOnDark={true}
        barStyle={'overlay'}
        showCallToActionButton={true}
        background={{ image: data.hero.edges[0].node.childImageSharp.fluid }}
      />
      <PageContent />
      <Footer />
    </React.Fragment>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    hero: allFile(filter: { relativePath: { eq: "hero-amfmradiostreaming.jpg" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2880, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
